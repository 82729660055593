import { logError } from 'lib/observability';
import debounce from 'lodash/debounce';
import { isAutoScrollEnabled } from 'modules/listening/features/autoScroll/autoScrollStore';
import { getAutoScrollAnchor } from 'modules/listening/features/autoScroll/utils';
import type { Virtualizer } from 'modules/listening/features/reader/components/classic/ClassicReaderV2';
import { renderedContentStoreSelectors } from 'modules/listening/features/reader/stores/renderedContentStore';
import { TimeoutError, withTimeout } from 'utils/promise';

import type {
  ContentOverlayRange,
  CurrentWordAndSentenceOverlayEvent,
  CurrentWordAndSentenceOverlayHelper,
  ReadingBundle as SDKReadingBundle,
  RenderedContentOverlayProvider,
  SpeechSentence
} from '@speechifyinc/multiplatform-sdk';

import { MultiplatformSDKInstance } from '../../sdk';
import { PlaybackInfo } from '../playback';
import { LeafClassicElementInfo } from '../reader/classic/ClassicBlockInfo';
import { ClassicReaderInfo } from '../reader/classic/ClassicReaderInfo';
import { HighlightingInfo, OverlayInfo, PlaybackCursorPosition, SentenceMark } from './base';
import { getRelativeRect } from './utils';

type ClassicOverlayContentRef = LeafClassicElementInfo;

export class ClassicOverlayInfo extends OverlayInfo<ClassicOverlayContentRef> {
  private _cleanUpFunctions: (() => void)[] = [];

  private _overlayProvider: RenderedContentOverlayProvider<ClassicOverlayContentRef>;
  private _overlayHelper: CurrentWordAndSentenceOverlayHelper<ClassicOverlayContentRef>;

  public readonly reader: ClassicReaderInfo;

  constructor(
    private readonly sdk: MultiplatformSDKInstance,
    private readonly bundle: SDKReadingBundle,
    public readonly playbackInfo: PlaybackInfo,
    private _scrollerElement: HTMLElement,
    private _overlayElement: HTMLElement,
    private _virtualizer: Virtualizer
  ) {
    super();
    this._overlayProvider = new sdk.sdkModule.RenderedContentOverlayProvider<ClassicOverlayContentRef>();
    this._overlayHelper = new sdk.sdkModule.CurrentWordAndSentenceOverlayHelper<ClassicOverlayContentRef>(bundle.playbackControls, this._overlayProvider);
    this.reader = new ClassicReaderInfo(sdk, bundle);

    const cleanUpZoomListener = this.customHighlightingEventEmitter.addEventListener('zoom', async () => {
      if (this._visibleBlockIndices.size > 0) {
        await new Promise(resolve => setTimeout(resolve, 1));
        this._notifyVisibleSentenceMarksChanged();
      }
    });
    this._cleanUpFunctions.push(cleanUpZoomListener);
  }
  addRenderedContentToOverlayProvider = async (): Promise<void> => {
    await this.reader.initialize();
    const leafElements = this.reader.getLeafElements();

    const ObjectRef = this.sdk.sdkModule.ObjectRef;

    leafElements.forEach(element => {
      this._overlayProvider.addRenderedContent(new ObjectRef(element), element.sdkText);
    });
  };

  public get scrollerElement(): HTMLElement {
    return this._scrollerElement;
  }

  public get overlayElement(): HTMLElement {
    return this._overlayElement;
  }

  public get virtualizer(): Virtualizer {
    return this._virtualizer;
  }

  public updateScrollerElement = (scrollerElement: HTMLElement): void => {
    this._scrollerElement = scrollerElement;
  };

  public updateOverlayElement = (overlayElement: HTMLElement): void => {
    this._overlayElement = overlayElement;
  };

  public updateVirtualizer = (virtualizer: Virtualizer): void => {
    this._virtualizer = virtualizer;
  };

  override get overlayProvider(): RenderedContentOverlayProvider<ClassicOverlayContentRef> {
    return this._overlayProvider;
  }
  override get overlayHelper(): CurrentWordAndSentenceOverlayHelper<ClassicOverlayContentRef> {
    return this._overlayHelper;
  }

  override overlayRangeToRects(range: ContentOverlayRange<ClassicOverlayContentRef>, baseRect: DOMRect): DOMRect[] {
    const ref = range.ref.value;

    const element = renderedContentStoreSelectors.getRenderedContent(`${ref.elementId}`);

    if (!element) {
      return [];
    }

    const textNode = element.childNodes[0];
    if (!(textNode instanceof Node)) return [];
    const r = new Range();

    try {
      r.setStart(textNode, range.startIndex);
      r.setEnd(textNode, range.endIndex);
    } catch (error) {
      return [];
    }

    return Array.from(r.getClientRects()).map(rect => getRelativeRect(baseRect, rect));
  }

  private overlayRangeToText = (range: ContentOverlayRange<ClassicOverlayContentRef>): string => {
    const { startIndex, endIndex } = range;
    const ref = range.ref.value;

    const element = renderedContentStoreSelectors.getRenderedContent(`${ref.elementId}`);

    if (!element || !element.childNodes[0]) {
      return '';
    }

    return element.childNodes[0].textContent!.slice(startIndex, endIndex);
  };

  // TODO(albertusdev): Consider refactoring this into abstract class which simply calls `highlightContainer.getClientRects()[0]`
  override getBaseRect(): DOMRect {
    return this.overlayElement.getClientRects()[0];
  }
  get scroller(): HTMLElement {
    return this.scrollerElement;
  }

  private async maybeScrollToBlockIndex(blockIndex: number): Promise<void> {
    if (!isAutoScrollEnabled()) {
      return;
    }
    if (!this.isBlockVisible(blockIndex)) {
      this.virtualizer.scrollToIndex(blockIndex, {
        align: 'center',
        smooth: true
      });
      await new Promise(resolve => setTimeout(resolve, 250));
    }
  }

  override getActiveSentenceTopPositionForAutoScroll = async (sentenceOverlayRanges: ContentOverlayRange<ClassicOverlayContentRef>[]) => {
    if (sentenceOverlayRanges.length === 0) {
      return null;
    }

    const firstSentence = sentenceOverlayRanges[0];

    const activeBlockIndex = firstSentence.ref.value.blockIndex;

    await this.maybeScrollToBlockIndex(activeBlockIndex);

    const baseRect = this.getBaseRect();
    const rects = this.overlayRangeToRects(firstSentence, baseRect);

    if (rects?.length === 0) {
      return null;
    }

    return rects[0].top;
  };

  get highlightContainer(): HTMLElement {
    return this.overlayElement;
  }

  private _latestHighlightingEvent: CurrentWordAndSentenceOverlayEvent<ClassicOverlayContentRef> | null = null;

  public listenToCurrentWordAndSentenceHighlighting = (callback: (currentWordAndSentence: HighlightingInfo) => void): (() => void) => {
    const overlayHelper = this.overlayHelper;

    const mapOverlayEventToCurrentWordAndSentence = async (event: CurrentWordAndSentenceOverlayEvent<ClassicOverlayContentRef>) => {
      try {
        const { sentenceOverlayRanges, wordOverlayRanges } = event;
        if (wordOverlayRanges.length === 0) {
          throw new Error('No sentence overlay ranges found');
        }
        const blockIndex = wordOverlayRanges[0].ref.value.blockIndex;
        await this.maybeScrollToBlockIndex(blockIndex);

        const baseRect = this.getBaseRect();

        const wordRects = wordOverlayRanges.flatMap(wordRange => {
          const rects = this.overlayRangeToRects(wordRange, baseRect);
          return rects;
        });

        const sentencesRects = sentenceOverlayRanges.flatMap(sentenceRange => {
          const rects = this.overlayRangeToRects(sentenceRange, baseRect);
          return rects;
        });

        return {
          word: { rects: wordRects },
          sentence: { rects: sentencesRects }
        };
      } catch (e) {
        return { word: { rects: [] }, sentence: { rects: [] } };
      }
    };

    const cleanUp = overlayHelper.addEventListener(async event => {
      this._latestHighlightingEvent = event;
      try {
        const highlightingInfo = await withTimeout(mapOverlayEventToCurrentWordAndSentence(this._latestHighlightingEvent), 2000);
        callback(highlightingInfo);
      } catch (e) {
        if (e instanceof TimeoutError) {
          // ignore timeout error and simply move on to the next event
          if (process.env.NODE_ENV === 'development') {
            console.warn('Timeout while processing highlighting event, moving on to the next event');
          }
          return;
        }
        logError(new Error(`Error while processing highlighting event: ${e}`));
      }
    });

    const cleanUpZoomListener = this.customHighlightingEventEmitter.addEventListener('zoom', async () => {
      await new Promise(resolve => setTimeout(resolve, 1));
      if (this._latestHighlightingEvent) {
        mapOverlayEventToCurrentWordAndSentence(this._latestHighlightingEvent).then(callback);
      }
    });

    this._cleanUpFunctions.push(cleanUp);
    this._cleanUpFunctions.push(cleanUpZoomListener);

    return () => {
      cleanUp();
      this._cleanUpFunctions = this._cleanUpFunctions.filter(f => f !== cleanUp && f !== cleanUpZoomListener);
    };
  };

  private sentenceToSentenceMark = (sentence: SpeechSentence): SentenceMark => {
    const CursorQueryBuilder = this.sdk.sdkModule.CursorQueryBuilder;
    const overlayRanges = this.overlayProvider.getOverlayRanges(sentence.text);
    return {
      playFromHere: (e: MouseEvent | TouchEvent) => {
        const isClickOverHyperlink = (): boolean => {
          // Determine the coordinates of the event
          let x, y;

          if ('clientX' in e && 'clientY' in e) {
            x = e.clientX;
            y = e.clientY;
          } else if ('touches' in e && e.touches[0]) {
            x = e.touches[0].clientX;
            y = e.touches[0].clientY;
          } else {
            // If no coordinates are available, return false
            return false;
          }

          const elements = document.elementsFromPoint(x, y);

          if (elements.length === 0) {
            return false;
          }

          const hyperlinks = elements.filter(el => {
            return el.tagName === 'A';
          });

          if (hyperlinks.length > 0) {
            const href = hyperlinks[0].getAttribute('href');

            if (href) {
              window.open(href, '_blank');
              return true;
            }
          }

          return false;
        };

        if (!isClickOverHyperlink()) {
          return this.playbackInfo.playFromQuery(CursorQueryBuilder.fromCursor(sentence.text.start));
        }
      },
      rects: overlayRanges.flatMap(range => this.overlayRangeToRects(range, this.getBaseRect()))
    };
  };

  private mapVisibleBlockIndexToSentenceMark = async (blockIndex: number): Promise<SentenceMark[]> => {
    const sentences = await this.reader.getSentences(blockIndex);
    return sentences.map(this.sentenceToSentenceMark);
  };

  private _clickToListenAbortController: AbortController | null = null;
  public override getRelevantClickToListenSentenceMark = async (x: number, y: number): Promise<SentenceMark | null> => {
    if (this._clickToListenAbortController) {
      this._clickToListenAbortController.abort();
    }
    this._clickToListenAbortController = new AbortController();
    const { signal } = this._clickToListenAbortController;

    const elements = document.elementsFromPoint(x, y);

    if (signal.aborted) {
      return null;
    }

    const relevantElement = elements.find(el => el.hasAttribute('data-block-index'));

    if (signal.aborted || !relevantElement) return null;

    const blockIndex = parseInt(relevantElement.getAttribute('data-block-index')!, 10);

    if (signal.aborted) {
      return null;
    }

    const sentences = await this.reader.getSentences(blockIndex);

    for (const sentence of sentences) {
      if (signal.aborted) {
        return null;
      }

      const overlayRanges = this.overlayProvider.getOverlayRanges(sentence.text);
      const clientRects = overlayRanges.flatMap(range => {
        if (signal.aborted) {
          return [];
        }
        return this.overlayRangeToRects(
          range,
          // We do comparison within the same client coordinate space and avoid any transformations
          new DOMRect(0, 0, 0, 0)
        );
      });

      if (clientRects.some(rect => x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom)) {
        return this.sentenceToSentenceMark(sentence);
      }
    }

    return null;
  };

  private _callbacks: ((visibleSpeechSentenceMarks: SentenceMark[]) => void)[] = [];
  public listenToLatestVisibleSentenceMarks = (callback: (visibleSpeechSentenceMarks: SentenceMark[]) => void): (() => void) => {
    this._callbacks.push(callback);
    return () => {
      this._callbacks = this._callbacks.filter(c => c !== callback);
    };
  };

  private _visibleBlockIndices: Set<number> = new Set();
  public onVisibleBlocksChanged = (visibleBlockIndices: number[]): void => {
    this._visibleBlockIndices = new Set(visibleBlockIndices);

    this._notifyVisibleSentenceMarksChanged();
  };

  private _notifyVisibleSentenceMarksChanged = debounce(async (): Promise<void> => {
    let sentenceMarks: SentenceMark[] = [];

    Array.from(this._visibleBlockIndices).forEach(async blockIndex => {
      const blockSentenceMarks = await this.mapVisibleBlockIndexToSentenceMark(blockIndex);
      sentenceMarks = [...sentenceMarks, ...blockSentenceMarks];

      if (this._visibleBlockIndices.has(blockIndex)) {
        this._callbacks.forEach(callback => {
          callback(sentenceMarks);
        });
      }
    });
  }, 100);

  public isBlockVisible = (blockIndex: number): boolean => {
    return this._visibleBlockIndices.has(blockIndex);
  };

  override getCurrentPlaybackCursorPosition(): PlaybackCursorPosition {
    if (!this._latestHighlightingEvent) {
      return 'notFound';
    }
    if (this._latestHighlightingEvent.wordOverlayRanges.length === 0) {
      return 'notFound';
    }
    const currentWordBlockIndex = this._latestHighlightingEvent.wordOverlayRanges[0].ref.value.blockIndex;

    const visibleBlockIndicesArray = Array.from(this._visibleBlockIndices);
    const minimumVisibleBlockIndex = Math.min(...visibleBlockIndicesArray);
    const maximumVisibleBlockIndex = Math.max(...visibleBlockIndicesArray);

    if (currentWordBlockIndex < minimumVisibleBlockIndex) {
      return 'above';
    }
    if (currentWordBlockIndex > maximumVisibleBlockIndex) {
      return 'below';
    }

    // Simply check if the current word is below or above the center of the screen
    const blockElement = renderedContentStoreSelectors.getRenderedBlock(currentWordBlockIndex);
    if (!blockElement) return 'notFound';

    const blockRect = blockElement.getClientRects()[0];
    const autoScrollAnchor = getAutoScrollAnchor();

    if (blockRect.top < autoScrollAnchor) return 'above';
    return 'below';
  }

  destroy(): void {
    this._cleanUpFunctions.forEach(cleanUpFunction => cleanUpFunction());
    this._cleanUpFunctions = [];
  }
}
