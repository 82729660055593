import { ErrorSource } from 'constants/errors';
import { logError } from 'lib/observability';
import { logAnalyticsEventOnDocumentCompleted } from 'modules/analytics/impl/documentAnalytics';
import { BASE_FACTOR_SPEED_WPM } from 'modules/speed/utils/constants';
import type { Nullable } from 'utils/types';

import type {
  AudioController,
  CursorQuery,
  PlaybackControls as SDKPlaybackControls,
  ReadingBundle as SDKReadingBundle,
  VoiceSpecOfAvailableVoice,
  WordsListenedEvent as SDKWordsListenedEvent,
  WordsListenedHelper
} from '@speechifyinc/multiplatform-sdk';

import type { MultiplatformSDKInstance } from '../../sdk';
import { SDKVoiceFacade, VoiceInfo } from '../voice';
import { PlayButtonState } from './constants';

type PlaybackTimeLoading = {
  isLoading: true;
};

type PlaybackTimeReady = {
  isLoading: false;
  currentTimeSeconds: number;
  totalTimeSeconds: number;
};

export type WordsListenedEvent = {
  count: number;
  voice: VoiceInfo;
  speedInRelativeFactor: number;
  speedInWPM: number;
};

export type PlaybackTime = PlaybackTimeLoading | PlaybackTimeReady;

class State {
  constructor(
    private sdkModule: MultiplatformSDKInstance['sdkModule'],
    private sdkPlaybackState: SDKPlaybackControls.State,
    private sdkAudioController: AudioController
  ) {}

  get latestPlaybackCursor() {
    return this.sdkPlaybackState.latestPlaybackCursor;
  }

  get playPauseButtonState() {
    switch (this.sdkPlaybackState.playPauseButton) {
      case this.sdkModule.PlayPauseButton.ShowPlay:
        return PlayButtonState.SHOW_PLAY;

      case this.sdkModule.PlayPauseButton.ShowPause:
        return PlayButtonState.SHOW_PAUSE;

      case this.sdkModule.PlayPauseButton.ShowRestart:
        return PlayButtonState.SHOW_RESTART;

      case this.sdkModule.PlayPauseButton.ShowBuffering:
        return PlayButtonState.SHOW_BUFFERING;

      default:
        return PlayButtonState.SHOW_BUFFERING;
    }
  }

  get latestProgressFraction() {
    return this.sdkPlaybackState.latestPlaybackProgressFraction;
  }

  get playbackTime(): PlaybackTime {
    const state = this.sdkPlaybackState.playbackTime;

    if (state instanceof this.sdkModule.PlaybackTime.Ready) {
      return {
        isLoading: false,
        currentTimeSeconds: state.currentTimeSeconds,
        totalTimeSeconds: state.totalTimeSeconds
      };
    }

    return {
      isLoading: true
    };
  }

  get wordsPerMinute() {
    return this.sdkPlaybackState.wordsPerMinute;
  }

  get playbackSpeed() {
    const wpm = this.wordsPerMinute;
    const playbackSpeed = wpm / BASE_FACTOR_SPEED_WPM;
    const playbackSpeedInUpperTen = Math.ceil(playbackSpeed * 20) / 20;
    return playbackSpeedInUpperTen;
  }

  get isPlaying() {
    return this.sdkAudioController.isPlaying;
  }
}

class Controls {
  constructor(public sdkPlaybackControls: SDKPlaybackControls) {}

  pause = () => {
    this.sdkPlaybackControls.pause();
  };

  pressPlayPause = () => {
    this.sdkPlaybackControls.pressPlayPause();
  };

  setSpeed = (speedInWPM: number) => {
    this.sdkPlaybackControls.setSpeed(speedInWPM);
  };

  setRelativeSpeed = (speedMultiplier: number) => {
    const inWpm = speedMultiplier * BASE_FACTOR_SPEED_WPM;
    this.sdkPlaybackControls.setSpeed(inWpm);
  };

  setVoice = (voice: VoiceInfo) => {
    const voiceSpec = SDKVoiceFacade.singleton.getVoiceSpec(voice);
    this.sdkPlaybackControls.setVoice(voiceSpec);
  };

  skipBackwards = () => {
    this.sdkPlaybackControls.skipBackwards();
  };

  skipForwards = () => {
    this.sdkPlaybackControls.skipForwards();
  };

  destroy = () => {
    this.sdkPlaybackControls.destroy();
  };

  onScrubStart = () => {
    const scrubber = this.sdkPlaybackControls.scrubber;
    scrubber.grab();
  };

  scrub = (newFraction: number) => {
    const scrubber = this.sdkPlaybackControls.scrubber;
    scrubber.scrub(newFraction);
  };

  onScrubEnd = () => {
    const scrubber = this.sdkPlaybackControls.scrubber;
    scrubber.release();
  };
}

type StateListener = (state: State) => void;

type WordsEventListener = (event: WordsListenedEvent) => void;

export class PlaybackInfo {
  private _controlsCache: Controls | null = null;
  private _stateListeners: StateListener[] = [];

  private _cleanUpListeners: (() => void)[] = [];

  private _wordsListenedHelper: WordsListenedHelper;
  private _wordsEventListeners: WordsEventListener[] = [];

  constructor(
    public readonly sdk: MultiplatformSDKInstance,
    public readonly bundle: SDKReadingBundle,
    public readonly initialVoice: VoiceSpecOfAvailableVoice
  ) {
    const cleanUpStateListener = bundle.playbackControls.addListener(sdkState => {
      const state = new State(sdk.sdkModule, sdkState, bundle.listeningBundle.audioController);
      this._stateListeners.forEach(listener => listener(state));
    });
    this._cleanUpListeners.push(cleanUpStateListener);

    this._wordsListenedHelper = new sdk.sdkModule.WordsListenedHelper(bundle.listeningBundle.audioController);
    this._setupWordsEventListener();
    this._setupWakeLock();

    this._stateListeners.push(this._listenForCompletion);
  }

  private _listenForCompletion = (state: State) => {
    if (state.latestProgressFraction >= 0.99) {
      logAnalyticsEventOnDocumentCompleted();
      this._stateListeners = this._stateListeners.filter(listener => listener !== this._listenForCompletion);
    }
  };

  private _setupWordsEventListener() {
    this._wordsListenedHelper.addEventListener((event: SDKWordsListenedEvent) => {
      const latestStats = event.stats.pop();
      if (!latestStats) return;

      const wordsListenEvent = {
        count: latestStats.count,
        voice: this.voiceOfCurrentUtterance,
        speedInRelativeFactor: this.latestState.playbackSpeed,
        speedInWPM: this.latestState.wordsPerMinute
      };

      this._wordsEventListeners.forEach(listener => listener(wordsListenEvent));
    });
  }

  private _wakeLockSentinel: Nullable<WakeLockSentinel> = null;

  private _acquireWakeLock = async () => {
    try {
      if ('wakeLock' in navigator && !this._wakeLockSentinel) {
        this._wakeLockSentinel = await navigator.wakeLock.request('screen');
      }
    } catch (err) {
      logError(new Error(`Error acquiring wake lock: ${err}`), ErrorSource.PLAYBACK);
    }
  };

  private _releaseWakeLock() {
    if ('wakeLock' in navigator && this._wakeLockSentinel) {
      this._wakeLockSentinel.release();
      this._wakeLockSentinel = null;
    }
  }

  private _setupWakeLock() {
    this.addStateListener(state => {
      if (state.isPlaying) {
        this._acquireWakeLock();
      } else {
        this._releaseWakeLock();
      }
    });
  }

  get latestState() {
    return new State(this.sdk.sdkModule, this.bundle.playbackControls.state, this.bundle.listeningBundle.audioController);
  }

  get controls() {
    if (!this._controlsCache || this._controlsCache.sdkPlaybackControls !== this.bundle.playbackControls) {
      this._controlsCache?.destroy();
      this._controlsCache = new Controls(this.bundle.playbackControls);
    }
    return this._controlsCache;
  }

  get voices() {
    return this.bundle.listeningBundle.voices.map(v => SDKVoiceFacade.singleton.mapToVoiceInfo(v.voice));
  }

  get voiceOfCurrentUtterance() {
    const voiceSpec = this.bundle.playbackControls.state.voiceOfCurrentUtterance || this.initialVoice;
    return SDKVoiceFacade.singleton.mapToVoiceInfo(voiceSpec);
  }

  get voiceOfPreferenceOverride() {
    return this.bundle.playbackControls.state.voiceOfPreferenceOverride;
  }

  playFromQuery = (query: CursorQuery) => {
    return this.bundle.listeningBundle.audioController.play(query);
  };

  resume = () => {
    if (this.latestState.isPlaying) return;
    this.bundle.listeningBundle.audioController.resume();
  };

  pause = () => {
    this.controls.pause();
  };

  // TODO(overhaul): Override to VoiceInfo
  subscribeToVoiceOfCurrentUtterance(callback: (voice: Nullable<VoiceSpecOfAvailableVoice>) => void): () => void {
    this.bundle.playbackControls.addListener;
    return this.bundle.playbackControls.subscribeToVoiceOfCurrentUtterance(callback);
  }

  // TODO(overhaul): Override to VoiceInfo
  subscribeToVoiceOfPreferenceOverride(callback: (voice: Nullable<VoiceSpecOfAvailableVoice>) => void): () => void {
    return this.bundle.playbackControls.subscribeToVoiceOfPreferenceOverride(callback);
  }

  removeStateListener(listener: StateListener) {
    this._stateListeners = this._stateListeners.filter(l => l !== listener);
  }

  addStateListener(listener: StateListener): () => void {
    this._stateListeners.push(listener);
    listener(this.latestState);
    return () => {
      this.removeStateListener(listener);
    };
  }

  addWordsListenedListener(listener: WordsEventListener): () => void {
    this._wordsEventListeners.push(listener);
    return () => {
      this._wordsEventListeners = this._wordsEventListeners.filter(l => l !== listener);
    };
  }

  destroy() {
    this._stateListeners = [];
    this._wordsEventListeners = [];
    this._cleanUpListeners.forEach(cleanup => cleanup());

    this._wordsListenedHelper.destroy();
    this._controlsCache?.destroy();
    this._releaseWakeLock();
  }
}
